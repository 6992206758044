import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { FaShareSquare } from 'react-icons/fa'
import Link from './Link'

const ShareButton = (props) => {
  return (
    <IconButton
      as={(p) => <Link {...p} />}
      colorScheme="progressBlack"
      isRound
      size="lg"
      href="https://www.facebook.com/sharer.php?u=https://streetfood.helloislander.cc/"
      icon={<FaShareSquare size="1.25em" />}
      {...props}
    />
  )
}

export default ShareButton
