import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { map } from 'lodash'
import InApp from 'detect-inapp';
import { Helmet } from 'react-helmet';

import Container from '../../components/Container'
import Box from '../../components/Box'
import Image from '../../components/Image'
import Text from '../../components/Text'
import Button from '../../components/Button'
import isIos from '../../components/utils/isIos';
import { responsive } from '../../components/ThemeProvider/theme';

import useResponsive from '../../contexts/mediaQuery/useResponsive'
import headerContext from '../../contexts/header/context';

import cover from '../table.png'
import mobilecover from './mobilecover.svg'
import title from './title.svg'

const HomePage = () => {
  const { isTablet } = useResponsive()
  const { setHideHeader } = useContext(headerContext)
  useEffect(() =>
    window.scrollTo({
    top: 0,
    behavior: "smooth"
  }), [])
  useEffect(() => setHideHeader(false), [])
  useFirebaseConnect('foods')
  useFirebaseConnect('cityScores')
  const foods = useSelector(state => state.firebase.data.foods)
  const cityScores = useSelector(state => state.firebase.data.cityScores)
  const foodsNumber = map(foods).length
  const votes = map(cityScores, (v) => v).reduce((res, value) => res = res + value , 0)
  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)
  const isFBorIGorMes = inapp.browser === 'messenger' || inapp.browser === 'instagram' || inapp.browser === 'facebook'
  return (
    <Container position="relative" height="100%" overflow="hidden">
      <Helmet>
        <title>台灣小吃大調查</title>
      </Helmet>
      <Box position="absolute" width={responsive('220%', '90vw', '92.5%', null, 'auto')} bottom={isFBorIGorMes ? '-1.5em' : 0} left="50%" transform={responsive('translate(-61%, 1em)', 'translate(-50%, 1em)', 'translate(-50%, 21.5%)')}>
        <Image src={isTablet ? mobilecover : cover} />
        <Box.Absolute bottom={responsive('100vw', '42.5vw', '77%')} left={responsive('40%', '37.5%', '50%')} width={responsive('22.5%', '50%', '29.1%')} transform={responsive('translateY(100%)', 'translate(-50%, 100%)', 'translate(-50%, 100%)')}>
          <Image src={title} />
          <Text mt="0.75rem" mb="1rem" fontSize={responsive('0.875em', null, '1.25em')}>哪個小吃最能代表全台各縣市？自己愛的小吃自己挺！<br />快來推薦你最愛的在地小吃與店家，讓全台灣都知道你口袋名單的厲害！</Text>
          <Box textAlign="center">
            <Button.Black logEvent="recommend" width="99.25%" to="/recommend/1">立刻推薦</Button.Black>
          </Box>
          <Box textAlign="center" mt="0.5em" mb="0.75em">
            <Button to="/leader" logEvent="leader" width="99.25%">即時排名</Button>
          </Box>
          <Text align="center" fontSize={responsive('0.75em', '0.875em')}>已經有{votes || 0}人推薦了{foodsNumber || 0}道小吃</Text>
        </Box.Absolute>
      </Box>
    </Container>
  )
}

export default HomePage
