import { useEffect, useState } from "react"
import { useWindowSize } from "react-use"
import Bowser from "bowser"

const browser = Bowser.getParser(window.navigator.userAgent)
const isDesktop = browser.getPlatformType() === 'desktop'

let isFocus
const useTrueWindowHeight = (key) => {
  const { height } = useWindowSize()
  const [trueHeight, setTrueHeight] = useState(height)
  useEffect(() => {
    if (!isDesktop) {
      const handleFocus = () => {
        isFocus = true
      }
      const handleBlur = () => {
        isFocus = false
      }
      const allEvents = []
      const inputs = document.querySelectorAll('input')
      inputs.forEach(ele => {
        ele.addEventListener('focus', handleFocus)
        ele.addEventListener('blur', handleBlur)
        allEvents.push(() => {
          ele.removeEventListener('focus', handleFocus)
          ele.removeEventListener('blur', handleBlur)
        })
      })
      return () => allEvents.forEach(unbind => unbind())
    }
  }, [key])
  useEffect(() => {
    if (!isFocus || isDesktop) setTrueHeight(height)
  }, [height])
  return trueHeight
}

export default useTrueWindowHeight
