import React, { useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { IconButton } from '@chakra-ui/button'
import { IoMdArrowRoundBack } from "react-icons/io"

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { responsive } from '../../components/ThemeProvider/theme'

const RecommendModule = ({
  recommendData,
  filter,
  chioce,
  city,
  isTablet,
  setChoose,
  isRecommend,
  setRecommend,
  lastChoice,
  setLastChoice
}) => {
  useEffect(() => {
    setRecommend(null)
  }, [city, filter])
  const handleRecommned = (name) => {
      setRecommend(1)
      setChoose(name)
      setLastChoice(chioce)
    }
  const recommends = recommendData[isRecommend || filter]
  return (
    <>
      <Flex borderBottom="1px solid" pb="0.625em" px={responsive('0.25em', '1em')}>
        {Boolean(isRecommend) && (
          <IconButton
            mr="0.75rem"
            onClick={() => {
              setRecommend(0)
              setChoose(lastChoice)
            }}
            minWidth="2rem"
            height="2rem"
            color="white"
            isRound
            bg="black"
            icon={<IoMdArrowRoundBack size="1.5em" />}
          />
        )}
        <Box>
          <Text fontWeight="700" fontSize="1.25em">{chioce}</Text>
          <Text fontSize="0.75em">
            來自 <Text.Inline fontWeight="bold">{city}</Text.Inline> 的內行{+filter ? '評論' : '推薦店家'}
          </Text>
        </Box>
      </Flex>
      <Box pt="1.25em" flex={1}>
        <Scrollbars
          style={{ height: isTablet ? '70vh' : '100%' }}
          renderThumbVertical={(p) => <Box {...p} bg="black" rounded="full" />}
        >
          <Box pl="0.25em" pr="0.875em">
            {recommends.length ? recommends.map(({ name }, key) => (+filter || isRecommend) ? (
              <Box
                bg="bgGray"
                fontSize="0.875em"
                border="2px solid"
                borderRadius="2rem"
                px="0.875rem"
                py="0.75rem"
                fontWeight="500"
                mb="0.625rem"
                key={key}
              >
              {name}</Box> ) : (
                <Button
                  width="100%"
                  bg="white"
                  fontSize="0.875em"
                  px="1.25rem"
                  py="1.0625rem"
                  fontWeight="700"
                  mb="0.625rem"
                  justifyContent="flex-start"
                  onClick={() => handleRecommned(name)}
                  key={key}
                >{name}</Button>
              )
            ) : <Text fontSize="0.875em">等你來{isRecommend || +filter ? '評論' : '推薦'}！</Text> }
          </Box>
        </Scrollbars>
      </Box>
    </>
  )
}

export default RecommendModule
