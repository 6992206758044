import React, { useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from 'firebase'
import ReactPixel from 'react-facebook-pixel';

import Layout from './containers/Layout'
import HomePage from './containers/HomePage'
import Recommend from './containers/Recommend'
import Leaderboard from './containers/Leaderboard'
import AdminPage from './containers/Admin'
import FoodsPage from './containers/Foods'
import LoginPage from './containers/Admin/Login'
import LogoutPage from './containers/Admin/Logout'
import NotFoundPage from './containers/NotFoundPage'

// import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from './services/firebase/authHelper'
ReactPixel.init('3935330633179044');

function Analytics() {
  const { pathname } = useLocation();
  const analytics = useRef()
  useEffect(() => {
    // init analytics
    analytics.current = firebase.analytics()
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (analytics.current) {
        analytics.current.logEvent('page_view', {
          page_path: pathname,
        });
      }
      ReactPixel.pageView();
    }
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Layout>
      <Analytics />
      <Switch>
        <Route exact path="/" component={HomePage} />
        {/* <Route path="/admin/login" component={userIsNotAuthenticatedRedir(LoginPage)} />
        <Route path="/admin/logout" component={userIsAuthenticatedRedir(LogoutPage)} />
        <Route path="/admin" component={userIsAuthenticatedRedir(AdminPage)} /> */}
        <Route path="/recommend/:id" component={Recommend} />
        <Route path="/leader" component={Leaderboard} />
        <Route path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/auth/allfoods" component={FoodsPage} />
        <Route path="/auth" component={AdminPage} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default App;
