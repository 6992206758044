import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import Box from './Box'
import Flex from './Flex'
import Button from './Button'

const ConfirmModal = ({ isOpen, onClose, handleSubmit, disabled }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="0.75em" mt="0.5em">
        <ModalCloseButton />
        <ModalBody as={Flex} justifyContent="center" py="2em">
          <Box mx="1em">
            <Button
              onClick={handleSubmit}
            >
              確定{typeof isOpen === 'string' ? isOpen : '送出'}
            </Button>
          </Box>
          <Box mx="1em">
            <Button onClick={onClose} disabled={disabled}>
              取消
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
