import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { Link as UiLink } from "@chakra-ui/react";
import styled from '@emotion/styled'

import useAnalytics from '../services/firebase/useAnalytics';

const Link = forwardRef(({ button, to, onClick, logEvent, ...props }, ref) => {
  const analytics = useAnalytics()
  const handleClick = (e) => {
    if (typeof onClick === 'function' ) {
      onClick(e)
    }
    if (logEvent) {
      analytics.logEvent('click', {
        name: logEvent
      });
    }
  }
  if (to) {
    return (
      <UiLink
        onClick={handleClick}
        as={RouterLink}
        to={to}
        {...props}
        ref={ref}
      />
    )
  }
  return (
    <UiLink
      target="_blank"
      onClick={handleClick}
      {...props}
      ref={ref}
    />
  );
});

Link.displayName = 'Link';

export default styled(Link)``
