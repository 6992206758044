import { useEffect, useRef, useState } from "react"
import { Textarea } from "@chakra-ui/react"
import styled from '@emotion/styled'
import { responsive } from "./ThemeProvider/theme"

const StyledTextarea = styled(Textarea)`
  padding-left: 2rem;
  padding-right: 2.125rem;
  min-height: unset;
  overflow: hidden;
`

const autoGrowTextArea = (textarea, scrollbarRef) => {
  let adjustedHeight = Math.max(textarea.current.scrollHeight, textarea.current.clientHeight)
  if (adjustedHeight > textarea.current.clientHeight) {
    textarea.current.style.height = adjustedHeight + 'px';
    setTimeout(() => {
      if (scrollbarRef && scrollbarRef.current) {
        scrollbarRef.current.scrollToBottom()
      }
    }, 200)
  }
}

const wordNum = /[\u4e00-\u9fa5]/g
// const isEng = /[A-Za-z]/g
// const isNumber = /^[0-9]+$/;

const ReTextarea = ({ label, food, onChange, value, scrollbarRef, ...props }) => {
  const ref = useRef()
  const [isBlur, setBlur] = useState(true)
  useEffect(() => {
    if (isBlur) {
      ref.current.style.height = '3.875em';
    }
  }, [isBlur])
  const customValue = (value && isBlur && value.replace(wordNum, 'aa').length > 34) ? value.substring(0, 17) + '...' : value
  return (
    <StyledTextarea
      borderRadius="2.5rem"
      row={1}
      value={customValue}
      border="2px solid"
      borderColor="black"
      fontWeight="bold"
      py="1.25rem"
      lineHeight="1.5"
      fontSize={responsive('1em', '1.125em')}
      resize="none"
      boxShadow={(!value || !isBlur) && '2px 2px 4px 1px rgba(0, 0, 0, 0.25)'}
      onChange={(e) => {
        const length = ref.current.value.replace(wordNum, 'aa').length
        if (length <= 200) {
          onChange(e)
        }
      }}
      _hover={{
        borderColor: 'black',
      }}
      _focus={{
        outline: 'none',
      }}
      _placeholder={{
        color: 'customGray',
        fontWeight: 400
      }}
      ref={ref}
      onKeyUp={() => autoGrowTextArea(ref, scrollbarRef)}
      onBlur={() => {
        setBlur(true)
      }}
      onClick={() => {
        setBlur(false)
        setTimeout(() => autoGrowTextArea(ref, scrollbarRef))
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          // prevent default behavior
          e.preventDefault();
        }
      }}
      {...props}
      bg={(value && isBlur) && 'darkGray'}
    />
  )
}

export default ReTextarea
