import { useMemo } from "react"
import { useFirebase } from "react-redux-firebase"

const useAnalytics = () => {
  const firebase = useFirebase()
  return useMemo(() => {
    if (process.env.NODE_ENV === 'production') return firebase.analytics()
    return { logEvent: console.log }
  }, [firebase])
}

export default useAnalytics
