import React, { useContext, useEffect, useMemo, useState, useRef, createElement } from 'react'
import { map, sortBy } from 'lodash'
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { IoIosSearch, IoIosCloseCircle } from "react-icons/io";
import { CgScrollV } from "react-icons/cg";
import { Scrollbars } from 'react-custom-scrollbars';

import Board from '../../components/Board'
import Box from '../../components/Box'
import Button from '../../components/Button'
import Text from '../../components/Text'
import Image from '../../components/Image'
import Flex from '../../components/Flex'
import Input from '../../components/Input'
import ShareButton from '../../components/ShareButton'
import { responsive } from '../../components/ThemeProvider/theme'
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import headerContext from '../../contexts/header/context';

import Taiwan from './Taiwan';

import one from './one.svg';
import two from './two.svg';
import three from './three.svg';
import medal from './medal.svg';
import goalmedal from './goalmedal.svg';
import arrowdown from './arrow.svg';
import Filter from './Filter';
import RecommendModule from './RecommendModule';
import PointingAnimate from '../../components/PointingAnimate';

const medals = [one, two, three]

export const IconBox = (props) => <Box.Absolute top="50%" left={responsive('0.625em', '1em')} transform="translateY(-50%)" width={responsive('2.25em', '2.5em')} {...props} />

export const SearchInput = ({ setSearch, filter, searchValue }) => (
  <Box.Relative width="100%">
    <Input
      placeholder={`請輸入你要搜尋的${Boolean(+filter) ? '店家' : '小吃'}關鍵字`}
      px="2.75rem"
      py={responsive('0.875rem', null, '0.625rem')}
      onChange={(e) => setSearch(e.target.value)}
      value={searchValue}
      boxShadow={!searchValue && '2px 2px 4px 1px rgba(0, 0, 0, 0.25)'}
      fontSize={responsive('0.875em', '1em')}
      logEvent="search"
      _hover
    />
    <Box.Absolute top="50%" transform="translateY(-50%)" left="0.875em">
      <IoIosSearch size="1.25em" />
    </Box.Absolute>
    <Box.Absolute top="50%" transform="translateY(-50%)" right="0.5em">
      <IconButton
        px="0"
        py="0"
        _hover={{
          bg: 'transparent'
        }}
        onClick={() => setSearch(false)}
        icon={<IoIosCloseCircle size="2em" />} />
    </Box.Absolute>
  </Box.Relative>
)

const ArrowDownSeeMore = ({ isTablet }) => (
  <Flex position={responsive('relative', null, 'absolute')} top="110%" left="0" width={responsive('auto', null, '200%')} color="black" align="center" justify={responsive('center', null, 'flex-start')} pt={responsive('2.125em', null, 0)}>
      {createElement(isTablet ? 'div' : PointingAnimate,
        {},
        <Box width={responsive('1em', '1.125em')} pr="0.125em"><Image src={arrowdown} /></Box>
      )}
    <Text fontSize={responsive('0.875em', '0.875em')}>下滑探索更多台灣小吃的有趣故事</Text>
  </Flex>
)

const Leader = ({
  cityScores,
  localRepreScores,
  localStores,
  localStoresFood,
  localStoresNote,
  city,
  setCity
}) => {
  const { isMobile, isTablet } = useResponsive()
  const [hoverCity, setHover] = useState()
  const [filter, setFilter] = useState('0')
  const [chioce, setChoose] = useState()
  const [isRecommend, setRecommend] = useState(0)
  const [lastChoice, setLastChoice] = useState()
  const [searchValue, setSearch] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setPage } = useContext(headerContext)
  const [canScroll, setScroll] = useState()
  const votes = map(cityScores, (v) => v).reduce((res, value) => res = res + value , 0)

  // const selectData = [{
  //   func: setCity,
  //   options: citys,
  //   value: city,
  //   name: 'city'
  // }, {
  //   func: setFilter,
  //   options: [{ label: '依小吃', value: '0' }, { label: '依店家', value: '1' }],
  //   value: filter,
  //   name: 'filter'
  // }]
  const localFoodTop = useMemo(() => localRepreScores[city] ? sortBy(map(localRepreScores[city], (value, key) => ({ name: key, value })), 'value').reverse() : [], [localRepreScores, city])
  const localStoreTop = useMemo(() => localStores[city] ? sortBy(map(localStores[city], (value, key) => ({ name: key, value })), 'value').reverse() : [], [localStores, city])
  const scrollbarRef = useRef()
  useEffect(() => {
    if(localFoodTop && localRepreScores[city] && !+filter && !isMobile) { setChoose(localFoodTop[0].name) }
    if(localStoreTop && localStores[city] && +filter && !isMobile) { setChoose(localStoreTop[0].name) }
    if(!localRepreScores[city]) { setChoose('無推薦小吃') }
    setPage('leader')
    return () => setPage(false)
  }, [localRepreScores, localStores, filter, city, isMobile])
  useEffect(() =>
      window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), [])
  useEffect(() =>{
    setScroll(scrollbarRef.current.getScrollHeight() > scrollbarRef.current.getClientHeight())
  }, [filter, city])
  const storesRecommend = useMemo(() => localStoresFood[city] ? sortBy(map(localStoresFood[city], (value, key) => ({ name: key, foods: value })).filter(s => s.foods[chioce]), [`foods[${chioce}]`]).reverse() : [], [chioce, localStoresFood])
  const notes = useMemo(() => localStoresNote[city] ? map(localStoresNote[city][chioce], (value, key) => ({ name: key, value })) : [], [chioce, localStoresNote])
  const showData = !searchValue ? [localFoodTop, localStoreTop] : [localFoodTop.filter(({ name }) => name.includes(searchValue)), localStoreTop.filter(({ name }) => name.includes(searchValue))]
  const recommendData = [storesRecommend, notes]
  // console.log(recommendData)
  return (
    <Flex
      flexDir={responsive('column', null, 'row')}
      bg="bgGray"
      pl={responsive('0.75em', null, '1em')}
      pr={responsive('0.75em', null, '1.5em')}
      pt={responsive('1.5em', null, '0.75em')}
      pb={responsive('1em', null, '0.75em')}
      minHeight={responsive('auto', null, '35.875em')}
    >
      <Box position="relative" width={responsive('100%', null, '28.5%')}>
        <Board display="inline-block" width={responsive('100%', null, 'auto')} textAlign={responsive('center', null, 'auto')} px="1em" py="0.75em">
          <Text fontWeight="bold" fontSize={responsive('1.375em', null, '1.125em')}>全台各地小吃票選排名</Text>
          <Text fontSize={responsive('0.875em', '1em')}>目前已有 {votes} 人投票</Text>
        </Board>
        {!isTablet && (
          <Box maxWidth="21.5em" ml="4.625em">
            <Taiwan
              active={city}
              score={cityScores}
              chosen={hoverCity}
              setHover={setHover}
              onClick={(name) => setCity(city === name ? '全台灣' : name)}
            />
          </Box>
        )}
        {!isTablet && (hoverCity || city) && (
          <Board
            px="1.25em"
            py="0.75em"
            bg="black"
            color="white"
            position="absolute"
            bottom="12.5%"
            left="0"
            zIndex={99}
          >
            <Text fontSize="1.25em" fontWeight="700">{hoverCity || city}</Text>
            <Text>已有 {(city === '全台灣' && !hoverCity ) ? votes : cityScores[hoverCity || city] || 0} 人投票</Text>
            <ArrowDownSeeMore />
          </Board>
        )}
      </Box>
      <Flex
        flexDir="column"
        position="relative"
        mt={responsive('1em', null, '0')}
        ml={responsive('0', null, '5.25em')}
        mr={responsive('0', null, '1em')}
        width={responsive('100%', null, '36%')}
        height={responsive('22.5em', null, 'auto')}
      >
        {!isTablet && (
          <Board align="center" as={Flex} px="1.375em" py="1em">
            {searchValue || searchValue === '' ? (
              <SearchInput searchValue={searchValue} filter={filter} setSearch={setSearch} />
            ) : (
              <>
                <Text fontSize="0.875em" mr="0.75em">排名<br/>顯示</Text>
                <Filter
                  logEvent
                  setFilter={setFilter}
                  setCity={setCity}
                  city={city}
                  filter={filter}
                  setSearch={setSearch}
                />
              </>
            )}
          </Board>
        )}
        <Board as={Flex} flexDir="column" mt={responsive('0', null, '0.5em')} py={responsive('0.875em', null, '0.75em')} pl={responsive('0.75em', null, '1.375em')} pr="1em" flex={1} overflowY={responsive('none', null, 'auto')}>
          {isTablet && (
            <Flex mb="1em">
              {searchValue || searchValue === '' ? (
                <SearchInput searchValue={searchValue} filter={filter} setSearch={setSearch} />
              ) : (
                <Filter
                  logEvent
                  setFilter={setFilter}
                  setCity={setCity}
                  city={city}
                  filter={filter}
                  setSearch={setSearch}
                />
              )}
            </Flex>
          )}
          <Box position="relative" flex={1}>
            <Scrollbars
              autoHide={false}
              style={{ height: isTablet ? '13em' : '100%' }}
              overflowX="hidden"
              renderThumbVertical={(p) => <Box {...p} bg="black" rounded="full" />}
              ref={scrollbarRef}
            >
              {showData[filter || 0].length ? showData[filter || 0].map(({ name }, i) => (
                <Box mt={i && '0.625em'} position="relative" pr="1em" key={i}>
                  <Button
                    justifyContent="flex-start"
                    pl={responsive('3.125rem', '3.875rem')}
                    fontSize={responsive('0.875em', '1.125em')}
                    py={responsive('1.09375rem', '0.84375rem')}
                    bg={(name === chioce || name === lastChoice) && 'darkGray'}
                    boxShadow={(name === chioce || name === lastChoice) ? 'none' : '2px 2px 4px 1px rgba(0, 0, 0, 0.25)'}
                    onClick={() => {
                      setChoose(name)
                      setRecommend(0)
                      setLastChoice(null)
                      onOpen()
                    }}
                    _focus
                    width="100%"
                    fontWeight="700"
                  >{name.substring(0, 12) + (Boolean(name.length > 12) ? '...' : '')}</Button>
                  {i < 3 ? (
                    <IconBox>
                      <Image src={goalmedal} />
                      <Box.AbsCenter top="40%" fontWeight="bold">{i + 1}</Box.AbsCenter>
                    </IconBox>
                  ) : (
                    <IconBox>
                      <Image src={medal} />
                      <Box.AbsCenter fontSize={toString(i + 1).length > 2 ? '0.75em' : '1em'} fontWeight="bold">{i + 1}</Box.AbsCenter>
                    </IconBox>
                  )}
                </Box>
              )) : '等你來推薦！'}
            </Scrollbars>
            {canScroll && isTablet && (
              <Box.Absolute right="-0.5em" bottom="2em">
                <PointingAnimate color="bgOrange">
                  <CgScrollV size="1.5em" />
                </PointingAnimate>
              </Box.Absolute>
            )}
          </Box>
          {isTablet && (
            <Flex justify="flex-end" align="center">
              <Text fontSize="0.875em" mr="0.625em">分享台灣小吃大調查<br />讓更多人來投票！</Text>
              <ShareButton />
            </Flex>
          )}
        </Board>
        {!isTablet && <Box.Gradient />}
      </Flex>
      {isTablet ? (
        <ArrowDownSeeMore isTablet />
      ) : (
        <Board as={Flex} flexDir="column" flex={1} py="1em" px="0.75em" position="relative">
          <RecommendModule
            city={city}
            isRecommend={isRecommend}
            recommendData={recommendData}
            filter={filter}
            chioce={chioce}
            lastChoice={lastChoice}
            setLastChoice={setLastChoice}
            setRecommend={setRecommend}
            setChoose={setChoose}
          />
          <Box.Gradient />
        </Board>
      )}
      {isTablet && (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" allowPinchZoom>
          <ModalOverlay />
          <ModalContent mx="0.75em" mt="0.5em" maxHeight="96vh">
            <ModalCloseButton
              onClick={() => {
                setChoose(null)
                setLastChoice(null)
            }} />
            <ModalBody as={Flex} flexDir="column">
              <RecommendModule
                isRecommend={isRecommend}
                recommendData={recommendData}
                isTablet={isTablet}
                city={city}
                filter={filter}
                chioce={chioce}
                lastChoice={lastChoice}
                setLastChoice={setLastChoice}
                setRecommend={setRecommend}
                setChoose={setChoose}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  )
}

export default Leader
