import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import Flex from '../../components/Flex'
import Text from '../../components/Text'
import Input from '../../components/Input'
import Button from '../../components/Button'
import ConfirmModal from '../../components/ConfirmModal'
import { citys } from '../Leaderboard/Filter'
import { mapValues, set } from 'lodash'

const switchName = [
  {
    name: 'oldName',
    label: '原名稱',
  },
  {
    name: 'newName',
    label: '新名稱',
  },
]

// const snaplocalStoresFood = {
//   city: {
//     台中肉圓: 1,
//   },
//   cityw: {
//     台中肉圓: 1,
//     肉圓: 2,
//   }
// }

const types = ['food', 'store']
const Switch = ({ filter }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firebase = useFirebase()
  const auth = useSelector(state => state.firebase.auth)
  const toast = useToast()
  // const test = {
  //     ...snaplocalStoresFood.cityw,
  //     ...mapValues(snaplocalStoresFood.city, (v , k) => {
  //     if (snaplocalStoresFood.cityw[k]) {
  //       return v + snaplocalStoresFood.cityw[k]
  //     }
  //     return v
  //   })
  // }
  // console.log(test)
  return (
    <Formik
      initialValues={{}}
    >
      {({
        handleChange,
        values
      }) => {
        const switchData = {
          ...values,
          type: types[+filter],
          citys,
          auth: auth.displayName,
          timestamp: firebase.database.ServerValue.TIMESTAMP
        }
        return (
        <Form>
          <Flex align="center">
            <Text mr="1em">{types[filter]}名稱轉換</Text>
            {switchName.map(({ name, label }) => (
              <Flex alignItems="center" key={name}>
                <Text>{label}</Text>
                <Input
                  flex={1}
                  mx="1em"
                  py="0.5em"
                  name={name}
                  onChange={handleChange}
                />
              </Flex>
            ))}
            <Flex alignItems="center" mx="1em">
              <Text mr="0.5em">永久轉換</Text>
              <Field as="input" type="checkbox" name="irreversible" onChange={handleChange} />
            </Flex>
            <Button type="button" disabled={!values.oldName || !values.newName} onClick={onOpen}>確認</Button>
            <ConfirmModal
              isOpen={isOpen}
              onClose={onClose}
              handleSubmit={() => {
                Promise.all([
                  firebase.push('switch', switchData),
                ]).then(() => {
                  toast({
                    title: "成功上傳",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  })
                  onClose()
                })
              }}
            />
          </Flex>
        </Form>
      )}
    }
    </Formik>
  )
}

export default Switch
