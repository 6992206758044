import React from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import Circle from './Circle'

// const Corner = ({ transform, ...props }) => (
//   <Box width="100%" {...props}>
//     <Box
//       width="100%"
//       pt="100%"
//       borderLeft="1px solid currentColor"
//       borderBottom="1px solid currentColor"
//       transform={transform}
//     />
//   </Box>
// )

const Chevron = ({ children, ...props }) => (
  <Circle width="2.5rem" bg="black" {...props}>
    <MdKeyboardArrowLeft size="3em" />
  </Circle>
)

const ChevronRight = ({ children, ...props }) => (
  <Circle width="2.5rem" bg="black" {...props}>
    <MdKeyboardArrowRight size="3em" />
  </Circle>
)

// const ChevronTop = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" top="0" left="0" transform="translate(0%, -120%)" {...props}>
//     <Box transform="rotate(-90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

// const ChevronBottom = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" bottom="0" left="0" transform="translate(0%, 120%)" {...props}>
//     <Box transform="rotate(90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

Chevron.Left = Chevron
Chevron.Right = ChevronRight
// Chevron.Top = ChevronTop
// Chevron.Bottom = ChevronBottom

export default Chevron;
