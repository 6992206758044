import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { map } from 'lodash'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';

import Box from '../../components/Box'
import Container from '../../components/Container'
import theme from '../../components/ThemeProvider/theme'

import FoodData from './FoodData'
import StoreData from './StoreData'
import Switch from './Switch'
import Filter from '../Leaderboard/Filter'
import { SearchInput } from '../Leaderboard/Leader'

const Foods = () => {
  useFirebaseConnect('localRepreScores')
  const localRepreScores = useSelector(state => state.firebase.data.localRepreScores)
  useFirebaseConnect('localStoresFood')
  const localStoresFood = useSelector(state => state.firebase.data.localStoresFood)
  useFirebaseConnect('localStores')
  const localStores = useSelector(state => state.firebase.data.localStores)
  useFirebaseConnect('localStoresNote')
  const localStoresNote = useSelector(state => state.firebase.data.localStoresNote)
  const auth = useSelector(state => state.firebase.auth)
  const isLogin = isLoaded(auth) && !isEmpty(auth)
  const [city, setCity] = useState('全台灣')
  const [filter, setFilter] = useState('0')
  const [search, setSearch] = useState()
  if (!isLogin) return <Redirect to="/" />
  if (!localRepreScores || !localStoresFood || !localStores) return null
  const foodData = map(localRepreScores[city], (v, k) => ({ name: k, value: v })).filter(d => search ? d.name.includes(search) : d)
  const storeData = map(localStoresFood[city], (v, k) => ({ name: k, value: v })).filter(d => search ? d.name.includes(search) : d)
  return (
    <StickyContainer>
      <Sticky>
        {({
          style,
          isSticky
        }) => (
          <Box style={style} zIndex={99} position="relative" bg="white" px="3em" mt={isSticky && theme.headerHeight} pt="1em">
            <Switch filter={filter} city={city} />
            <Box my="1em">
              {search || search === '' ? (
                <SearchInput searchValue={search} filter={filter} setSearch={setSearch} />
              ) : (
                <Filter
                  setFilter={setFilter}
                  setCity={setCity}
                  city={city}
                  filter={filter}
                  setSearch={setSearch}
                />
              )}
            </Box>
          </Box>
        )}
      </Sticky>
      <Container pb="2em">
        {/* <Add filter={filter} city={city} /> */}
        {Boolean(+filter) ? storeData.map(({ name }) =>
          <StoreData noteData={localStoresNote[city]} storeData={localStoresFood} stores={localStoresFood[city]} name={name} city={city} key={name} storeValue={localStores[city]} />
        )
        : foodData.map(({ name }) => (
          <FoodData foodData={localRepreScores} foods={localRepreScores[city]} city={city} name={name} key={name} />
        ))}
      </Container>
    </StickyContainer>
  )
}

export default Foods
