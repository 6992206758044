import { css } from '@emotion/react'

import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const os = browser.getOSName()
const platform = browser.getPlatformType()
const mac = platform !== 'desktop' || os === 'macOS'

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap');
  html {
    height: 100%;
  }

  body {
    min-width: 100%;
    // min-height: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
    > div {
      height: 100%;
    }
  }

  ${mac ? `
  ::-webkit-scrollbar {
    min-width: 1px;
    min-height: 1px;
    max-width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  ` : ''}

  img {
    width: 100%;
  }
`;
