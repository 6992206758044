export default [
  {
    title: "終於等到你，還好我沒放棄！\n親愛的美食獵人，哪個城市總讓你最想推薦在地小吃呢？",
    required: true,
    name: "city",
    questions: [
      {
        options: [
          "台北市","基隆市","新北市","宜蘭縣","新竹縣","新竹市","桃園市","苗栗縣",
          "台中市","彰化縣","南投縣","嘉義縣","嘉義市","雲林縣","台南市","高雄市",
          "澎湖縣","屏東縣","台東縣","花蓮縣","金門縣","連江縣","其他地區"
        ],
        tag: "Dropdown",
      }
    ]
  },
  {
    title: (data) => `居然是${data}！\n你覺得最能代表${data}的小吃是什麼呢？`,
    required: true,
    name: "foods",
    note: '最多3道',
    questions: [
      {
        placeholder: '填完整小吃名稱，店名於下題填寫',
        label: '第一道小吃',
        tag: "ReactSelectInput",
        option: "suggestFoods"
      },
      {
        placeholder: '填完整小吃名稱，店名於下題填寫',
        label: '第二道小吃',
        tag: "ReactSelectInput",
        option: "suggestFoods"
      },
      {
        placeholder: '填完整小吃名稱，店名於下題填寫',
        label: '第三道小吃',
        tag: "ReactSelectInput",
        option: "suggestFoods"
      },
    ]
  },
  {
    title: "一種美食，各自表述！\n快推薦我們該去哪裡吃這些代表小吃吧～",
    required: false,
    name: "stores",
    note: '選填',
    questions: (foods) => foods.map(d => (
      {
        label: d,
        placeholder: '輸入推薦小吃的店家名稱！',
        tag: "ReactSelectInput",
        option: "suggestStores"
      }))
  },
  {
    title: "你為什麼推薦這家小吃店？告訴我們原因或他的特色吧！",
    required: false,
    name: "recommends",
    note: '選填',
    questions: (foods, stores) => foods.reduce((res, d, i) => {
      if(stores[i]) {
        res.push({ label: stores[i], food: d, placeholder: '請輸入最多一百字說明', tag: "Textarea" })
      }
      return res
    }, [])
  },
  {
    title: "據說，全台的美食獵人，都在找一份有著各地小吃的神秘地圖⋯\n留下資料，就能收到此調查的最終結果與各縣市小吃地圖！",
    desc: '本次資料收集僅會做為島民集合相關計畫之後續活動通知以及相關應用。若想暫停收到相關通知，也可以透過郵件與我們隨時聯繫，收到後我們將替你進行相關後續處理！',
    required: false,
    note: '選填',
    questions: [
      {
        label: "E-mail",
        name: "info.email",
        placeholder: "請輸入"
      },
      {
        label: "年齡",
        name: "info.age",
        placeholder: "請輸入",
        type: "number"
      },
      {
        options: ["男性", "女性"],
        label: "生理性別",
        name: "info.sexuality",
        tag: "Dropdown"
      },
      // {
      //   label: "職業",
      //   name: "info.job",
      //   options: [
      //     "勞務人員","一般技術人員","一般行政、事務職員","業務人員","管理階層",
      //     "軍人／政府機關／教職人員","自營業","專門自由業（如：醫師、律師等）",
      //     "農／林／漁／牧人員","學生","待業中／無業／退休","家庭主婦／夫","其他"
      //   ],
      //   tag: "Dropdown"
      // },
      {
        label: "給我們的話",
        name: "info.note",
        placeholder: '請輸入',
        tag: "Textarea"
      },
    ]
  },
]
