import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom';

const useAuthRedirect = (to, isLogin = true) => {
  const auth = useSelector(state => state.firebase.auth)
  let history = useHistory();
  useEffect(() => {
    if (isLoaded(auth)) {
      if ((isLogin && !isEmpty(auth)) || (!isLogin && isEmpty)) {
        history.push(to)
      }
    }
  }, [auth, isLogin, to])
}

export default useAuthRedirect
