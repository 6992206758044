import React, { createElement, useContext, useEffect, useMemo, useRef } from 'react'
import { Progress, IconButton, useDisclosure } from "@chakra-ui/react"
import { useFormikContext } from 'formik';
import { MdArrowDropDown } from "react-icons/md";
import { CgArrowLeft } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { isFunction, get, map, compact, uniq, lastIndexOf, isArray, isObject, mapValues } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocalStorage } from 'react-use';
import InApp from 'detect-inapp';
import sanitizeHtml from 'sanitize-html';
import ReactPixel from 'react-facebook-pixel';

import ReactSelectInput from '../../components/ReactSelectInput'
import Input from '../../components/Input'
import Box from '../../components/Box'
import Tag from '../../components/Tag'
import Flex from '../../components/Flex'
import Select from '../../components/ReactSelect'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Image from '../../components/Image'
import Textarea from '../../components/Textarea'
import ComfirmModal from '../../components/ComfirmModal'

import data from './data'
import qcover from './qtable.png'
import mobile from './mobile_table.svg'
import theme, { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive';
import headerContext from '../../contexts/header/context';
import isIos from '../../components/utils/isIos';
import useTrueWindowHeight from './useTrueWindowHeight';
import useAnalytics from '../../services/firebase/useAnalytics';

const tags = {
  Textarea,
  ReactSelectInput,
  // FirebaseAutoSuggestTagInput,
}

const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)
const isFBorIGorMes = inapp.browser === 'messenger' || inapp.browser === 'instagram' || inapp.browser === 'facebook'

// const isEng = /[A-Za-z]/g
// const isNumber = /^[0-9]+$/
const isChinese = /^[\u4E00-\u9FA5]+$/

const Recommend = ({ id }) => {
  const { values, handleChange } = useFormikContext()
  const { isMobile, isTablet, isLaptop } = useResponsive()
  const { setHideHeader, setPage } = useContext(headerContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const scrollbarRef = useRef()
  const isLast = data.length == id
  const [, setCity] = useLocalStorage('city', '全台灣');
  const windowHeight = useTrueWindowHeight(id)
  const analytics = useAnalytics()
  useEffect(() => {
    setHideHeader(isMobile)
    setPage('recommend')
    return () => {
      setPage(false)
      setCity('全台灣')
    }
  }, [isMobile])
  useEffect(() => {
    analytics.logEvent('level_start', { level_name: id })
  }, [id])
  useFirebaseConnect('foods')
  useFirebaseConnect('localStores')
  const foods = useSelector(state => state.firebase.data.foods)
  const localStores = useSelector(state => state.firebase.data.localStores)
  const suggestFoods = useMemo(() => map(foods, (v, k) => k).filter(d => values.foods ? !values.foods.includes(d) : d), [foods, values])
  const suggestStores = useMemo(() => uniq(map(localStores, (v) => v).reduce((res, d) => {
    res.push(...Object.keys(d))
    return res
  }, [])), [localStores])
  const isRepeat = id == 2 && Boolean(compact(values.foods).length) && typeof values.foods !== 'undefined' && compact(values.foods).length > uniq(values.foods).length
  const suggest = { suggestFoods, suggestStores }
  if (id > 1 && !values.city) return <Redirect to="/recommend/1" />
  const nowData = data[id - 1]
  if (nowData.name === 'recommends' && !compact(values.stores).length) return <Redirect to={`/recommend/${+id + 1}`} />
  const totalStep = data.length
  const d = isFunction(nowData.questions) ? nowData.questions(values.foods.filter(v => v), values.stores) : nowData.questions
  const valuefoods = compact(values.foods).length && values.foods.reduce((res, f) => {
    if (!res[f]) {
      res[f] = 1
      return res
    }
    res[f] = res[f] + 1
    return res
  }, {})
  const notChinese = values.foods && compact(values.foods).filter(d => !isChinese.test(d)).length > 0
  const newValues = mapValues(values, (v) => {
    if (isArray(v)) { return v.map(d => Boolean(d) ? sanitizeHtml(d).trim() : "")}
    if (isObject(v)) { return mapValues(v, value => Boolean(value) ? sanitizeHtml(value).trim() : "") }
    return sanitizeHtml(v)
  })
  return (
    <Flex
      pt={responsive('1em', '2em')}
      height="100%"
      flexDirection={responsive('column', 'row')}
      position="relative"
      overflow="hidden"
    >
      <Box position="absolute" bottom={responsive('-1em', 0)} width={['210%', '130%', '115%', '30%', 'auto']} left={responsive('50%', null , 0,)} transform={responsive('translateX(-50%)', null, 'none')}>
        <Image src={isTablet ? mobile : qcover} />
      </Box>
      <Flex
        flexDir="column"
        ml={responsive(0, null, '36%')}
        pl={responsive(0, null, '5.5em')}
        px={responsive(0, '2em', 0)}
        flex={1}
        minWidth={responsive('auto', '28em', null)}
        position={responsive('absolute', 'relative', 'relative')}
        left="1em"
        right="1em"
        bottom={responsive('147.5vw', '80vw', 'auto')}
        transform={responsive('translateY(100%)', null, 'none')}
      >
        <Flex align="center">
          <Box flex={1}>
            <Progress max={totalStep} min={0} value={+id} size="sm" bg="cyan" borderRadius="1em" colorScheme="progressBlack" />
          </Box>
          <Text fontSize="0.75em" fontWeight="bold" ml="0.75rem">{id} / {totalStep}</Text>
        </Flex>
        <Flex flexDir="column" mt={responsive('28.5vw', '15vw', '1.5em')} flex={1}>
          <Flex fontWeight="bold" justify="flex-start" fontSize={responsive('1.125em', '1.25em')}>
            <Text mr="0.25rem">Q{id}.</Text>
            <Box>
              <Flex flexDir="column">
                <Text whiteSpace="pre-wrap">
                  {isFunction(nowData.title) ? nowData.title(values.city) : nowData.title}
                  {nowData.note && <Text.Inline fontWeight="400" fontSize={responsive('0.875rem', '1rem')}>({nowData.note})</Text.Inline>}
                </Text>
                {/* {nowData.note && isMobile && <Text.Inline fontWeight="400" fontSize={responsive('0.875rem', '1rem')}>({nowData.note})</Text.Inline>} */}
              </Flex>
            </Box>
          </Flex>
          <Box flex={1}>
            <Scrollbars
              overflowX="hidden"
              autoHide={false}
              style={{ height: isMobile ? '74vw' : isTablet ? '42vw' : isLaptop ? '100%' : '100%' }}
              renderThumbVertical={(p) => <Box {...p} bg="black" rounded="full" />}
              ref={scrollbarRef}
            >
              <Box pr="1.5em" mb={id == 5 && isMobile ? '2.5em' : isTablet && '2em'}>
                <Box>
                  {d.map(({ label, name, tag, options, option, food, placeholder }, i) => options ? (
                    <Box.Relative mt={responsive('1.5rem', '1.75rem')} key={i}>
                      <Select
                        bg={(values[nowData.name] || get(values, name)) && 'darkGray'}
                        onChange={(e) => {
                          handleChange(e)
                          if (nowData.name === 'city') {
                            setCity(e.target.value)
                          }
                        }}
                        value={values[nowData.name] || get(values, name)}
                        name={nowData.name || name}
                        DropdownIndicator={MdArrowDropDown}
                        menuHeight={isMobile ? '10em' : isTablet ? '14em' : 'auto'}
                        options={options}
                        isSearchable={false}
                        isMobile={isMobile}
                        key={i}
                        scrollbarRef={scrollbarRef}
                      />
                      {label && (
                        <Tag
                          left="2.125em"
                          bg="black"
                          color="white"
                        >
                          {label}
                        </Tag>
                      )}
                    </Box.Relative>
                  ) : (
                    <Box.Relative mt={responsive('1.375rem', '1.75rem')} key={`${nowData.name}${i}`}>
                      {createElement(tags[tag] || Input,
                        {
                          name: name || `${nowData.name}.${i}`,
                          isMobile,
                          placeholder,
                          DropdownIndicator: 'div',
                          options: option && values[name || nowData.name] ? suggest[option].concat(values[name || nowData.name][i]) : suggest[option],
                          isDisabled: (nowData.required && i) && !get(values, [nowData.name, 0]),
                          value: get(values, name) || get(values, [nowData.name, i]),
                          onChange: handleChange,
                          // onInputChange: handleChange,
                          bg: (get(values, name) || get(values, [nowData.name, i])) && 'darkGray',
                          scrollbarRef,
                        }
                      )}
                      {food && <Tag right="1.75rem" bg="white" fontSize="0.75em" boxShadow="1px 1px 1px rgba(0, 0, 0, 0.25)">{food}</Tag>}
                      {label && <Tag left="2.125em" bg="black" color="white">{label}</Tag>}
                      {values.foods && valuefoods[values.foods[i]] > 1 && lastIndexOf(values.foods, values.foods[i]) === i && isRepeat && <Text color="red" fontSize="0.75rem" pl="2rem">小吃名稱不可重複</Text>}
                      {values.foods && values.foods[i] && (!isChinese.test(values.foods[i])) && <Text color="red" fontSize="0.75rem" pl="2rem">請填寫中文名稱(不可含有空格及標點符號)</Text>}
                    </Box.Relative>
                  ))}
                </Box>
                {nowData.desc && <Text mt={responsive('0.625em', '1rem')} fontWeight="400" fontSize={responsive('0.75rem', '0.875rem')}>{nowData.desc}</Text>}
              </Box>
            </Scrollbars>
          </Box>
        </Flex>
      </Flex>
      <Flex
        align="center"
        position={responsive('absolute', null, 'relative')}
        width={responsive('100%', null, 'auto')}
        bottom={responsive('1.875em', '4em', '0')}
        justify={responsive('space-between', null, 'auto')}
        px={responsive('1.5em', null, '4.375em')}
      >
        <IconButton
          as={Button.Transparent}
          mr="1.5rem"
          color="black"
          bg="transparent"
          boxShadow="none"
          border="transparent"
          _hover={{ bg: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
            bg: 'transparent'
          }}
          _active={{
            outline: 'none',
            boxShadow: 'none',
            bg: 'transparent'
          }}
          icon={<CgArrowLeft size="2em" />}
          onClick={id == 1 && onOpen}
          logEvent={`back-${id}`}
          to={id > 1 && (id == data.length && !compact(values.stores).length ? `/recommend/${+id - 2}` : `/recommend/${+id - 1}`)}
        />
        <Button.Black
          type={id == data.length && 'submit'}
          fontSize={responsive('1em', '1.25em')}
          isDisabled={notChinese || isRepeat || (nowData.required && !Boolean(get(values, [nowData.name, 0])))}
          to={id < data.length && `/recommend/${+id + 1}`}
          boxShadow={isRepeat || (!nowData.required || values[nowData.name]) ? '2px 2px 4px 1px rgba(0, 0, 0, 0.25)' : 'none'}
          _hover={{ color: 'white', textDecoration: 'none', }}
          logEvent={`next-${id}`}
          onClick={() => {
            analytics.logEvent('level_end', { level_name: id })
            ReactPixel.track('ViewContent', { content_ids: [`level${id}`], content_type: '小吃關卡' });
          }}
        >
          {id == data.length ? '看結果' : '下一題'} <IoIosArrowForward />
        </Button.Black>
      </Flex>
      <ComfirmModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default Recommend
