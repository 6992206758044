import React, { useEffect, useState } from 'react'
import {
  useToast
} from "@chakra-ui/react"
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { compact, map } from 'lodash'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Input from '../../components/Input'
import Button from '../../components/Button'
import ConfirmModal from '../../components/ConfirmModal'

export const buttons = ["修改", '刪除']

const FoodData = ({ foods, name, city, storeName, foodData, ...props }) => {
  const [count, setCount] = useState()
  const [type, setOpen] = useState()
  const firebase = useFirebase()
  const toast = useToast()
  const auth = useSelector(state => state.firebase.auth)
  useEffect(() => setCount(foods[name]), [foods, name, city])
  const modifyData = {
    auth: auth.displayName,
    type,
    city,
    name,
    count: +count,
    timestamp: firebase.database.ServerValue.TIMESTAMP
  }
  const locate = map(foodData, (v, k) => {
    if(v[name]) {
      return k
    }
    return null
  })
  const similarData = foodData && Object.keys(foodData.全台灣).filter(d => (d !== name && d.includes(name))).length
  // console.log(similarData)
  return (
    <Box>
      <Flex align="center" my="1em" {...props}>
        <Text>{name}</Text>
        <Flex ml="2em">
          <Input
            mx="1em"
            textAlign="center"
            py="0.25em"
            width="5em"
            onChange={(e) => setCount(e.target.value)}
            value={count}
          />
        </Flex>
        {buttons.map((key, i) => (
          <Button
            mr="1em"
            onClick={() => setOpen(key)}
            key={i}
          >
            {key}
          </Button>
        ))}
        {!storeName && (
          <>
            <Text>這個食物在</Text>
            {compact(locate).map(city => (
              <Box px="0.125em" fontWeight="bold">{city}</Box>
            ))}
            <Text>有</Text>
          </>
        )}
        {Boolean(similarData) && <Text>｜這個食物可能有相似名稱</Text>}
        <ConfirmModal
          isOpen={type}
          onClose={() => setOpen(false)}
          handleSubmit={() => {
            firebase.push('modifyFood', modifyData).then(() => {
              if (storeName && type === '刪除') {
                firebase.remove(`localStoresFood/${city}/${storeName}/${name}`)
                setOpen(false)
                toast({
                  title: `${type}成功`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                })
                return
              }
              if (type === '刪除') {
                firebase.remove(`foods/${name}`)
                firebase.remove(`favScores/${name}`)
                firebase.remove(`localRepreScores/${city}/${name}`)
                setOpen(false)
                toast({
                  title: `${type}成功`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                })
                return
              }
              if (storeName) {
                firebase.update(`localStoresFood/${city}/${storeName}`, { [name]: +count })
                setOpen(false)
                toast({
                  title: `${type}成功`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                })
                return
              }
              firebase.update(`localRepreScores/${city}`, { [name]: +count })
              firebase.update(`favScores`, { [name]: +count })
              setOpen(false)
              toast({
                title: `${type}成功`,
                status: "success",
                duration: 2000,
                isClosable: true,
              })
            })
          }}
        />
      </Flex>
    </Box>
  )
}

export default FoodData
