import React from 'react'
import Box from './Box'

const Board = (props) => {
  return (
    <Box boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)" bg="white" borderRadius="1em" {...props} />
  )
}

export default Board
