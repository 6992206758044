import React from 'react';
import PropTypes from 'prop-types';

import { selectFirebase } from '../../services/firebase/selectors';
import Container from '../../components/Container';
import Button from '../../components/Button';
import useAuthRedirect from './useAuthRedirect';
import { Helmet } from 'react-helmet';

const LoginPage = ({ auth: { isLoaded }, firebase}) => {
  const login = () => {
    firebase.login({ provider: 'google', type: 'redirect' });
  }
  useAuthRedirect('/auth/allfoods')
  return (
    <Container py="1em">
      <Helmet>
        <title>登入 - 台灣小吃大調查</title>
      </Helmet>
      <Button disabled={!isLoaded} onClick={login}>
        Login
      </Button>
    </Container>
  );
}

LoginPage.propTypes = {
  firebase: PropTypes.shape({
    login: PropTypes.func,
  }),
  auth: PropTypes.shape({
    isLoaded: PropTypes.bool,
  }),
};

export default selectFirebase(['auth'])(LoginPage);
