import React, { useState, useEffect } from 'react'
import {
  useToast
} from "@chakra-ui/react"
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { compact, map } from 'lodash'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Input from '../../components/Input'
import Button from '../../components/Button'
import ConfirmModal from '../../components/ConfirmModal'

import FoodData, { buttons } from './FoodData'

const StoreData = ({ stores, name, storeValue, storeData, city, noteData }) => {
  const values = map(stores[name], (v, k) => ({ name: k, value: v }))
  const [type, setOpen] = useState()
  const [point, setStorePoint] = useState(storeValue[name])
  const firebase = useFirebase()
  const toast = useToast()
  const auth = useSelector(state => state.firebase.auth)
  useEffect(() => setStorePoint(storeValue[name]), [storeValue, name, city])
  const modifyData = {
    auth: auth.displayName,
    type,
    city,
    name,
    point: +point,
    timestamp: firebase.database.ServerValue.TIMESTAMP
  }
  const locate = map(storeData, (v, k) => {
    if(v[name]) {
      return k
    }
    return null
  })
  const similarData = storeData && Object.keys(storeData.全台灣).filter(d => (d !== name && d.includes(name))).length
  console.log(noteData)
  return (
    <Box borderBottom="1px solid" pt="1em">
      <Flex alignItems="center">
        <Text fontSize="1.25em">店家：{name}</Text>
        <Input
          mx="1em"
          width="8em"
          value={point}
          onChange={(e) => setStorePoint(e.target.value)}
          py="0.5em"
          textAlign="center"
        />
        {buttons.map((key, i) => (
          <Button
            mr="1em"
            onClick={() => setOpen(key)}
            key={i}
          >
            {key}
          </Button>
        ))}
        <Text>這個店家在</Text>
          {compact(locate).map(city => (
            <Box px="0.125em" fontWeight="bold">{city}</Box>
          ))}
          <Text>有</Text>
        {Boolean(similarData) && <Text>｜這個店家可能有相似名稱</Text>}
        <ConfirmModal
          isOpen={type}
          onClose={() => setOpen(false)}
          handleSubmit={() => {
            firebase.push('modifyStore', modifyData).then(() => {
              if (type === '刪除') {
                firebase.remove(`localStores/${city}/${name}`)
                firebase.remove(`localStoresFood/${city}/${name}`)
                setOpen(false)
                toast({
                  title: `${type}成功`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                })
                return
              }
              firebase.update(`localStores/${city}`, { [name]: +point })
              setOpen(false)
              toast({
                title: `${type}成功`,
                status: "success",
                duration: 2000,
                isClosable: true,
              })
            })
          }}
        />
      </Flex>
      {values.map((v) => (
        <FoodData foods={stores[name]} city={city} name={v.name} key={v.name} storeName={name} />
      ))}
      <Box pb="1em">
        <Text>店家評論：{!noteData[name] && '無'}</Text>
        {map(noteData[name], (v, k) => (k)).map((note, i) => (
          <Text key={i} mt={i && "0.5em"}>{i+1}. {note}</Text>
        ))}
      </Box>
    </Box>
  )
}

export default StoreData
