import React, { useEffect } from 'react'
import { Formik, Form } from 'formik';
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from "react-router-dom";
import sanitizeHtml from 'sanitize-html';

import { isArray, isObject, mapValues } from 'lodash';
import { Helmet } from 'react-helmet';
import Recommend from './Recommend';

window.onbeforeunload = function (e) {
  if(typeof e=='undefined'){
  e=window.event;
  }
  // For IE and Firefox prior to version 4
  if (e) {
      e.returnValue = 'Sure?';
  }
  // For Safari
  return 'Sure?';
}

const CustomFormik = ({ match: { params: { id } } }) => {
  const firebase = useFirebase()
  let history = useHistory();
  useEffect(() =>
    window.scrollTo({
    top: 0,
    behavior: "smooth"
  }), [])
  return (
    <>
      <Helmet>
        <title>第{id}題 - 台灣小吃大調查</title>
      </Helmet>
      <Formik
        initialValues={{
          stores: [''],
          recommends: ['']
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const newValues = mapValues(values, (v) => {
            if (isArray(v)) { return v.map(d => Boolean(d) ? sanitizeHtml(d).trim() : "")}
            if (isObject(v)) { return mapValues(v, value => Boolean(value) ? sanitizeHtml(value).trim() : "") }
            return sanitizeHtml(v)
          })
          try {
            await firebase.push('votes', newValues)
            history.push("/leader")
          } catch (e) {
            console.error(e)
          }
        }}
      >
        <Form style={{ height: "100%" }}>
          <Recommend id={id} />
        </Form>
      </Formik>
    </>
  )
}

export default CustomFormik
