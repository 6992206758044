import React, { useState } from 'react'
import {
  Heading,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { RiInstagramFill, RiFacebookCircleFill } from "react-icons/ri";
// import { HiOutlineMail } from "react-icons/hi";

import Box from '../components/Box';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Text from '../components/Text';
import Link from '../components/Link';
import Image from '../components/Image';

import logo from './headerlogo.svg'
import menutable from './menutable.svg'
import vector from './vector.png'
import ShareButton from '../components/ShareButton';
import useResponsive from '../contexts/mediaQuery/useResponsive';
import { responsive } from '../components/ThemeProvider/theme';
import ComfirmModal from '../components/ComfirmModal';

const linkButtons = [
  {
    to: '/recommend/1',
    text: '我也要推薦',
    bg: 'bgOrange',
    color: 'white',
    border: '2px solid',
    borderColor: 'bgOrange',
    _hover:
    {
      color: 'black',
      textDecoration: 'none',
      bg: 'darkGray',
      borderColor: 'bgGray'
    }
  },
  { href: 'https://url.relab.cc/streetfood_tohelloislander__article', text: '關於小吃大調查' }
]

const outsideLinks = [
  { href: 'https://url.relab.cc/streetfood_tohelloislander__article', text: '關於小吃大調查' },
  { href: 'https://url.relab.cc/streetfood_helloislander_eatshop', text: '可愛迷人的小吃設計品' },
  { href: 'https://url.relab.cc/streetfood_helloislander_eatarticle', text: '不為人知的小吃故事' },
]

export const socialmedia = [
  { Icon: RiInstagramFill, href: 'https://www.instagram.com/helloislander/', name: 'IG' },
  { Icon: RiFacebookCircleFill, href: 'https://www.facebook.com/HelloIslander/', name: 'FB' },
  { Icon: () => <Box width="1.25em"><Image src={vector} /></Box>, href: 'mailto:helloislander@relab.cc', name: 'Email' },
]

const Header = ({ siteTitle, page, ...props }) => {
  const { isMobile } = useResponsive()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [open, setOpenModal] = useState()
 return (
  <Flex
    as="header"
    position="fixed"
    px={responsive('1.25em', '2em')}
    bg="black"
    top={0}
    left={0}
    right={0}
    alignItems="center"
    justify="space-between"
    zIndex="docked"
    {...props}
  >
    <Heading as="h1" lineHeight="1" fontSize="1rem">
      {page === 'recommend' ? (
        <Box>
          <Button.Transparent px="0" py="0" _focus={{ bg: 'transparent' }} onClick={() => setOpenModal(true)} width={responsive('9.375rem', '11.375rem')}>
            <Image src={logo} />
          </Button.Transparent>
        </Box>
      ) : (
        <Link logEvent="headerLogo" to="/">
          <Box width={responsive('9.375rem', '11.375rem')}>
            <Image src={logo} />
          </Box>
        </Link>
      )}
    </Heading>
    {page === 'leader' && !isMobile && (
      <Box.AbsCenter>
        {linkButtons.map(({ to, href, text, ...props }, i) => (
          <Button logEvent={text} fontWeight="700" mx="0.375em" fontSize="1em" py="0.4375em" to={to} href={href} key={i} {...props}>{text}</Button>
        ))}
      </Box.AbsCenter>
    )}
    {page === 'leader' && !isMobile && (
      <Flex align="center">
        <Text mr="0.5rem" fontSize="0.875em" color="white">分享讓更多人來投票！</Text>
        <ShareButton logEvent="shareButton" minWidth="2.625rem" height="2.625rem" bg="white" color="black" />
      </Flex>
    )}
    {page === 'leader' && isMobile && (
      <Flex>
        {linkButtons.map(({ to, href, text, ...props }, i) => !i && (
          <Button logEvent={text} fontWeight="700" fontSize="0.875em" py="0.375rem" to={to} href={href} key={i} {...props}>{text}</Button>
        ))}
        <Button.Transparent
          _focus={{ bg: 'transparent', outline: 'none' }}
          mr="-0.5rem"
          as={IconButton}
          height="2.5rem"
          py="0"
          px="0"
          color="white"
          onClick={onOpen}
          logEvent="menu"
          icon={<AiOutlineMenu size="1.25em" />}
        />
      </Flex>
    )}
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay bg="rgba(117,117,117, 0.8)">
        <DrawerContent position="relative" maxWidth="100%" bg="transparent">
          <Box position="absolute" width="125vh" left="-20%" top="-5%">
            <Image src={menutable} />
          </Box>
          <DrawerBody pt="8.875em" position="relative">
            <Link px="0" to="/" onClick={onClose}>
              <Box width="9.375em" ml="0.5em">
                <Image src={logo} />
              </Box>
            </Link>
            <Box pt="1.25em" pb="1em">
              {outsideLinks.map(({ href, text }, i) => (
                <Box pt={i && '0.75rem'} key={i}>
                  <Button width="72.5%" justifyContent="flex-start" href={href} fontSize="0.875em" py="1.125rem" px="1.5rem">{text}</Button>
                </Box>
              ))}
            </Box>
            <Box pl="0.5em">
              {socialmedia.map(({ Icon, href }, k) => (
                <Button.Transparent
                  _focus={{ bg: 'transparent', outline: 'none' }}
                  leftIcon={<Icon size="1.875rem" />}
                  minWidth="1.5rem"
                  py="0"
                  px="0"
                  color="white"
                  href={href}
                  key={k}
                />
              ))}
            </Box>
          </DrawerBody>
          <DrawerCloseButton color="white" top="1.25rem" right="1rem" fontSize="1.75em" />
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    <ComfirmModal isOpen={open} onClose={() => setOpenModal(false)} />
  </Flex>
)}

export default Header
