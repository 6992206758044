import React from 'react';
import { Helmet } from 'react-helmet';

import Text from '../components/Text';

export default function NotFound() {
  return (
    <article>
      <Helmet>
        <title>NotFound - 台灣小吃大調查</title>
      </Helmet>
      <Text.H1>
        Page not found.
      </Text.H1>
    </article>
  );
}
