import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"

import memoizeOne from 'memoize-one';
import get from 'lodash/get';

const chakraBp = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "68em",
  xl: "98em",
  "2xl": "110em",
})

export const breakpoints = Object.values(chakraBp)
export const responsiveIndex = [
  [1, 'mobile'],
  [3, 'tablet'],
  [4, 'laptop'],
]

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

export const responsive = memoizeOne((...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
});

export const mobileOrDesktop = responsive

export const containerWidth = ["30em", "44em", "62em", "81.25em", "116em"];
export const containerPadding = responsive('1em', '2em')

const font = '"Noto Sans TC", Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
}

const primary = 'blue'
const secondary = 'green'
const danger = 'red'
const darkGray = '#C5C5C5'
const lightkGray = '#B1B1B1'
const bgGray = '#DADADA'
const gray = '#A8A8A8'
const bgLayout = '#757575'
const cyan = '#00B0C0'
const bgOrange = '#C13519'
const black = '#000000'

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    primary: get(theme.colors, `${primary}.400`),
    secondary: get(theme.colors, `${secondary}.400`),
    danger: get(theme.colors, `${danger}.400`),
    text: get(theme.colors, 'black'),
    black,
    progressBlack: {
      500: black
    },
    darkGray,
    bgLayout,
    gray,
    lightkGray,
    customGray: lightkGray,
    cyan,
    bgGray,
    bgOrange
  },
  breakpoints,
  containerWidth,
  headerHeight: '3.75em',
}

const customTheme = extendTheme(overrides)

export default customTheme
