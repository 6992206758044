import React from 'react'
import { format } from 'date-fns'
import { Divider } from '@chakra-ui/layout'

import Box from '../components/Box'
import Link from '../components/Link'
import Flex from '../components/Flex'
import Text from '../components/Text'
import Image from '../components/Image'
import Button from '../components/Button'
import { responsive } from '../components/ThemeProvider/theme'
import { socialmedia } from './Header'

import logo from './headerlogo.svg'
import useResponsive from '../contexts/mediaQuery/useResponsive'

const outLink = [{
  name: '關於小吃大調查',
  href: '/'
}, {
  name: 'Re-lab 官網',
  href: 'https://url.relab.cc/streetfood_relab'
}]

const links = [{
  name: '關於我們',
  href: '/'
}, {
  name: '必敗商品',
  href: 'https://url.relab.cc/streetfood_footer_helloislander_eatshop'
}, {
  name: '必知文化',
  href: 'https://url.relab.cc/streetfood_footer_helloislander_eatarticle'
}]

const helloislanderLink = [
  [
    {
      name: '必敗商品',
      href: 'https://url.relab.cc/streetfood_footer_helloislander_eatshop'
    },
    {
      name: '必知文化',
      href: 'https://url.relab.cc/streetfood_footer_helloislander_eatarticle'
    }
  ],
  [
    {
      name: '島民集合',
      href: 'https://url.relab.cc/streetfood_helloislander'
    },
    {
      // name: '小吃扭蛋',
      // href: '/'
    }
  ],
]

const LinkButton = (props) => <Button.Transparent color="white" fontWeight="400" px="0" py="0" fontSize="1em" {...props} />

const Footer = () => {
  const { isMobile } = useResponsive()
  return (
    <Flex
      bg="black"
      justify={responsive('center', 'space-between')}
      color="white"
      pt={responsive('3.125em', '2.75em')}
      pb={responsive('0.75em', '2.75em')}
      px={responsive('1em', '2.75em')}
    >
      <Box textAlign={responsive("center", 'left')}>
        <Link logEvent="footerLogo" to="/">
          <Box width="9.375em" mx="auto">
            <Image src={logo} />
          </Box>
        </Link>
        {isMobile ? (
          <Flex mt="2.5em">
            {links.map(({ name, href }, i) => (
              <Flex key={i}>
                {Boolean(i) && <Divider mx="1.75em" border="2px" orientation="vertical" />}
                <Link logEvent={`footer-${name}`} href={href}>{name}</Link>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Box mt={responsive('2em', '1.375em')}>
            {socialmedia.map(({ Icon, href, name }, k) => (
              <Button.Transparent
                logEvent={`footer-${name}`}
                _focus={{ bg: 'transparent', outline: 'none' }}
                leftIcon={<Icon size="1.875rem" />}
                minWidth="1.5rem"
                py="0"
                px="0"
                color="white"
                href={href}
                key={k}
              />
            ))}
          </Box>
        )}
        <Box mt={responsive('3.5em', '1.5em')} fontSize={responsive('0.625em', '0.75em')}>
          Copyright © {format(new Date(), 'yyyy')} Re-lab 團隊
        </Box>
      </Box>
      {!isMobile && (
        <Flex>
          <Box mr="6.375em">
            {outLink.map(({ name, href }, i) => (
              <Box key={i} mt={i && '1em'}>
                <LinkButton logEvent={`footer-${name}`} href={href}>
                  {name}
                </LinkButton>
              </Box>
            ))}
          </Box>
          <Flex align="flex-start">
            <Text fontSize="0.875em" mt="0.25rem" fontWeight="700">相關延伸</Text>
            <Flex ml="3em" mr="3.625em">
              {helloislanderLink.map((d, k) => (
                <Box key={k} ml={k && '2.5em'}>
                  {d.map(({ name, href }, i) => (
                    <Box key={i} mt={i && '1em'}>
                      <LinkButton logEvent={`footer-${name}`} href={href}>
                        {name}
                      </LinkButton>
                    </Box>
                  ))}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default Footer
