import React, { useMemo } from 'react'
import {
  IconButton,
} from "@chakra-ui/react"
import { isArray } from 'lodash'

import { IconBox } from './Leader'
import { IoIosSearch, IoIosArrowDown } from "react-icons/io";

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Image from '../../components/Image'
import Select from '../../components/ReactSelect'
import { responsive } from '../../components/ThemeProvider/theme'

import food from './food.svg';
import store from './store.svg';
import location from './location.svg';
import useResponsive from '../../contexts/mediaQuery/useResponsive';
import useAnalytics from '../../services/firebase/useAnalytics';

const selectIcons = [location, [food, store]]

export const citys = [
  "全台灣","台北市","基隆市","新北市","宜蘭縣","新竹縣","新竹市","桃園市","苗栗縣",
  "台中市","彰化縣","南投縣","嘉義縣","嘉義市","雲林縣","台南市","高雄市",
  "澎湖縣","屏東縣","台東縣","花蓮縣","金門縣","連江縣"
]

const Filter = ({ setCity, city, setFilter, filter, logEvent, setSearch, noSearch }) => {
  const analytics = useAnalytics()
  const { isMobile } = useResponsive()
  const selectData = [{
    func: setCity,
    options: citys,
    value: city,
    name: 'city'
  }, {
    func: setFilter,
    options: [{ label: '依小吃', value: '0' }, { label: '依店家', value: '1' }],
    value: filter,
    name: 'filter'
  }]
  return (
    <Flex align="center" flex={1}>
      {selectData.map(({ func, options, value, name }, i) => (
        <Box.Relative key={i} mr={responsive('0.5em', null, '0.75em')} flex={1}>
          <Select
            placeholder={false}
            bg="black"
            color="white"
            orderColor="black"
            fontSize={responsive('0.875em', null, '1em')}
            pady={isMobile ? '0.75rem' : '0.625rem'}
            padLeft={isMobile ? '2.375rem' : '3rem'}
            onChange={(e) => {
              func(e.target.value)
              if (logEvent) {
                analytics.logEvent('click', {
                  name,
                  select: e.target.value
                });
              }
              // if (logEvent) {
              //   analytics.logEvent('click', {
              //     name: e.target.name,
              //   });
              // }
            }}
            value={value}
            valueColor="white"
            DropdownIndicator={IoIosArrowDown}
            indicatorMr={isMobile ? '0.625rem' : '0.75rem'}
            indicatorWidth={isMobile ? '1.325rem' : 'auto'}
            isSearchable={false}
            isMobile={isMobile}
            options={options}
          />
          <IconBox left={responsive('0.75em', null, '1em')} width={responsive('1.375em', null, '1.75em')}>
            <Image src={isArray(selectIcons[i]) ? selectIcons[i][filter] : selectIcons[i]} />
          </IconBox>
        </Box.Relative>
      ))}
      {!noSearch && (
        <IconButton
          bg="black"
          color="white"
          isRound
          size="lg"
          onClick={() => {
            setSearch('')
            if (logEvent) {
              analytics.logEvent('click', {
                name: 'searchIcon'
              });
            }
          }}
          icon={<IoIosSearch size="1.25em" />}
        />
      )}
    </Flex>
  )
}

export default Filter
