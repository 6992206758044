import React, { forwardRef, useEffect, useMemo } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import { isObject, last } from 'lodash'
import styled from '@emotion/styled'
import { FaCheck } from "react-icons/fa";
import { useWindowSize } from 'react-use';

import Flex from './Flex';
import Box from './Box';
import theme from './ThemeProvider/theme';

const customStyles = {
  option: (provided, state) => {
    const lastOption = last(state.options)
    return {
    ...provided,
    borderBottom: state.children !== lastOption.label && '1px solid black',
    color: state.isSelected ? 'black' : state.isFocused ? 'black' : 'lightGray',
    padding: '0.75em 0 0.75em 0',
    background: state.isFocused ? 'lightGray' : 'white',
  }},
  menu: (provided) => ({
    ...provided,
    border: '2px solid',
    borderRadius: '2em',
    overflow: 'auto',
  }),
  menuList: (provided, props) => ({
    ...provided,
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    height: props.selectProps.menuHeight || 'auto'
  }),
  control: (styles, props) => ({
    ...styles,
    fontSize: props.selectProps.fontSize || props.selectProps.isMobile ? '1em' : '1.125em',
    borderRadius: '2.5em',
    border: '2px solid',
    paddingLeft: props.selectProps.padLeft || '2rem',
    paddingTop: props.selectProps.pady || '1.25rem',
    paddingBottom: props.selectProps.pady || '1.25rem',
    background: theme.colors[props.selectProps.bg] || 'white',
    borderColor: theme.colors[props.selectProps.borderColor] || 'black',
    minHeight: 'auto',
    lineHeight: 1.5,
    boxShadow: !props.selectProps.value && '2px 2px 4px 1px rgba(0, 0, 0, 0.25)',
    // opacity: props.isDisabled ? 0.3 : 1,
  }),
  indicatorSeparator : (styles) => ({
    ...styles,
    width: 0,
  }),
  indicatorsContainer : (styles, props) => ({
    ...styles,
    width: props.selectProps.indicatorWidth,
    marginRight: props.selectProps.indicatorMr || '0.75em',
    color: theme.colors[props.selectProps.valueColor] || 'black',
  }),
  singleValue: (styles, props) => ({
    ...styles,
    color: theme.colors[props.selectProps.valueColor] || 'black',
    fontWeight: 700,
    margin: 0,
    position: 'static',
    transform: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  placeholder: (styles, props) => ({
    ...styles,
    color: theme.colors.customGray,
    fontWeight: 400,
    fontSize: props.selectProps.windowWidth < 321 && '0.75rem'
  }),
  input: (styles, props) => ({
    ...styles,
    margin: 0,
    padding: 0
  }),
}


const Option = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;
  return (
    <Box
      css={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <Flex justify="space-between" align="center">
        {children}
        <Box>{isSelected && <FaCheck size="0.875rem" />}</Box>
      </Flex>
    </Box>
  );
};

const ReactSelect = forwardRef(({
  options,
  value,
  name,
  onChange,
  DropdownIndicator,
  isCreatable,
  scrollbarRef,
  inputValue,
  components,
  ...props
}, ref) => {
  const selectOptions = options.map(opt => isObject(opt) ? opt : ({ value: opt, label: opt }))
  const selectedOption = useMemo(() => {
    if (!value) return null
    return selectOptions.find(opt => opt.value === value)
  }, [value, selectOptions])

  const StyledSelect = useMemo(() => styled(isCreatable ? Creatable : Select)`
  .react-select__control:hover {
    border-color: black;
  }
  .react-select__control:focus {
    box-shadow: none;
  }
  `, [isCreatable])
  useEffect(() => {
    if ((value || inputValue) && scrollbarRef && scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom()
    }
  }, [scrollbarRef, inputValue])
  const { width } = useWindowSize();
  return (
    <StyledSelect
      windowWidth={width}
      value={selectedOption}
      options={selectOptions}
      styles={customStyles}
      placeholder="請選擇"
      classNamePrefix="react-select"
      components={{
        DropdownIndicator: ({ innerProps, isDisabled }) => <DropdownIndicator size="1.5em" {...innerProps} />,
        ClearIndicator: () => null,
        Option,
        ...components,
      }}
      onMenuOpen={() => {
        if (!isCreatable) {
          setTimeout(() => {
            if (scrollbarRef && scrollbarRef.current) {
              scrollbarRef.current.scrollToBottom()
            }
          })
        }
      }}
      onChange={(e) => {
        onChange({
          target: {
            value: e ? e.value : '',
            name: name || e.label
          },
          persist: () => {},
        })}
      }
      ref={ref}
      {...props}
    />
  )
})

export default ReactSelect
