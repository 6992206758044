import React from 'react'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Link from '../../components/Link'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Board from '../../components/Board'
import Carousel from '../../components/Carousel'
import Container from '../../components/Container'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../components/ThemeProvider/theme'
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import priceFormat from '../../utils/priceFormat'

import badge from './badge.jpg'
import foodsticker from './foodsticker.jpg'
import patch from './patch.png'
import postcard from './postcard.png'
import foodexperiment from './foodexperiment.png'
import madfood1 from './madfood_1.png'
import madrice3 from './madrice_3.png'
import michelin from './michelin.jpg'
import nightmarket from './nightmarket.jpg'
import twrice from './twrice.jpg'

const data = [
  {
    label: '小吃必敗商品',
    mobileLabel: '必敗商品',
    link: 'https://url.relab.cc/streetfood_helloislander_eatshop',
    content: [
      {
        title: '《台灣小吃系列》解剖貼紙',
        desc: '從解剖在地小吃，認識台灣在地文化',
        href: 'https://url.relab.cc/streetfood_tohi_snackstickers',
        src: foodsticker,
        price: 120,
        sale_price: 95
      },
      {
        title: '《台灣小吃系列》電繡布章',
        desc: '深刻體驗臺灣文化，從在地小吃開始',
        href: 'https://url.relab.cc/streetfood_tohi_snackclothchapter',
        src: patch,
        price: 250,
        sale_price: 210

      },
      {
        title: '把最經典的米食，全都放進口袋裡',
        desc: '《米樣》米食徽章別針／磁鐵',
        href: 'https://url.relab.cc/streetfood_tohi_riceclothchapter',
        src: badge,
        price: 180,
        sale_price: ''
      },
      {
        title: '一張懂南北，圖解台灣米小吃多樣性',
        desc: '《米樣》解剖米小吃明信片',
        href: 'https://url.relab.cc/streetfood_tohi_ricecard',
        src: postcard,
        price: 100,
        sale_price: 77
      },
    ]
  },
  {
    label: '小吃必知文化',
    mobileLabel: '必知文化',
    link: 'https://url.relab.cc/streetfood_helloislander_eatarticle',
    content: [
      {
        title: '一句話惹怒小吃愛好者之1',
        desc: '肉圓不就南蒸北炸？',
        src: madfood1,
        href: 'https://url.relab.cc/streetfood_tohi_snack1',
      },
      {
        title: '台灣小吃解剖實驗',
        desc: '小吃解剖後，原來是這樣！',
        src: foodexperiment,
        href: 'https://url.relab.cc/streetfood_tohi_nightmkt',
      },
      {
        title: '台灣夜市美食也有米其林認證！',
        desc: '米其林美食，你吃了幾種呢？',
        src: michelin,
        href: 'https://url.relab.cc/streetfood_tohi_michelin',
      },
      {
        title: '寶島夜市大比拼！',
        desc: '',
        src: nightmarket,
        href: 'https://url.relab.cc/streetfood_tohi_taiwannightmkt',
      },
      {
        title: '最完整台灣米懶人包！',
        desc: '一張圖認識100種經典蓬萊米',
        src: twrice,
        href: 'https://url.relab.cc/streetfood_tohi_taiwanrice',
      },
      {
        title: '一句話激怒吃米愛好者之3',
        desc: '蘿蔔糕不就是用煎的？',
        src: madrice3,
        href: 'https://url.relab.cc/streetfood_tohi_rice3',
      },
      // {
      //   title: '一句話激怒吃米愛好者之2',
      //   desc: '碗粿冷掉是要怎麼吃？',
      //   href: 'https://helloislander.cc/zh-hant/article/%e4%b8%80%e5%8f%a5%e8%a9%b1%e6%bf%80%e6%80%92%e5%90%83%e7%b1%b3%e6%84%9b%e5%a5%bd%e8%80%85%e4%b9%8b2',
      // },
      // {
      //   title: '一句話激怒吃米愛好者之1',
      //   desc: '米糕不就糯米做的肉臊飯嗎？',
      //   href: 'https://helloislander.cc/zh-hant/article/%e4%b8%80%e5%8f%a5%e8%a9%b1%e6%bf%80%e6%80%92%e5%90%83%e7%b1%b3%e6%84%9b%e5%a5%bd%e8%80%85%ef%bc%8d%e7%b1%b3%e7%b3%95%e4%b8%8d%e5%b0%b1%e7%b3%af%e7%b1%b3%e5%81%9a%e7%9a%84%e8%82%89%e8%87%8a%e9%a3%af',
      // },
    ]
  },
]

const HelloIslander = () => {
  const { isMobile, isTablet } = useResponsive()
  return (
    <Container pt={responsive('4.5em', '4.625em')} px="0" pb="1em">
      {data.map(({ label, link, mobileLabel, content }, i) => (
        <Box key={i} pb={responsive('4.5em', '5.625em')} px={responsive('0.75em', '6.25em')}>
          <Flex justify={responsive('center' , 'flex-start')} align={responsive('center' , 'flex-end')} pb={responsive(0, '0.5em')} px={responsive('0.5em', 0)}>
            <Box fontSize={responsive('1em', '1.25em')} fontWeight="bold" mr={responsive('0.75em', '1em')}>{isMobile ? mobileLabel : label}</Box>
            <Button.Black
              fontWeight="400"
              px={responsive('0.5rem', '0.875em')}
              py={responsive('0.25rem', '0.125em')}
              fontSize={responsive('0.875em', '1em')}
              href={link}
              logEvent={`more-${mobileLabel}`}
            >看更多</Button.Black>
          </Flex>
          <Carousel
            silderProps={{
              className: "center",
              centerMode: isTablet,
              slidesToShow: isTablet ? 1 : 4,
            }}
            overflowX={responsive('visible', 'none')}
            arrows={!isMobile}
            nodot
            mx="-0.625em"
          >
            {content.map(({ title, desc, href, src, price, sale_price }, k) => (
              <Box px="0.625em" py={responsive('0.625em', '1em')} as={Link} href={href} key={k}>
                <Board height="100%" pt="1.25em" pb="0.75em" px="1.125em">
                  <Box><BackgroundImage src={src} ratio={i ? 2 / 1 : 3 / 2} /></Box>
                  <Box fontWeight="bold" fontSize={responsive('1em', '1.125em')} borderLeft="3px solid" pl="0.5rem" borderColor="cyan" mt="0.75rem">{title.length > 12 ? title.substring(0, 12) + '...' : title}</Box>
                  <Text fontSize="0.875em" my="0.25rem">{desc}</Text>
                  {price && (
                    <Flex fontSize={responsive('1rem', '1.125rem')}>
                      <Text
                        fontWeight="bold"
                        textDecoration={sale_price && 'line-through'}
                      >
                        {priceFormat(price)}
                      </Text>
                      {sale_price && (
                        <Text
                          fontWeight="bold"
                          color="cyan"
                          ml="0.5em"
                        >
                          {priceFormat(sale_price)}
                        </Text>
                      )}
                    </Flex>
                  )}
                </Board>
              </Box>
            ))}
          </Carousel>
        </Box>
      ))}
    </Container>
  )
}

export default HelloIslander
