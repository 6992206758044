import React from 'react'
import Box from './Box'
import { responsive } from './ThemeProvider/theme'

const Tag = ({ children, ...props }) => (
  <Box.Absolute
    px="0.375rem"
    py="0.125rem"
    fontSize={responsive('0.75em', '0.875em')}
    fontWeight="500"
    top="0"
    transform="translateY(-45%)"
    borderRadius="1rem"
    lineHeight="1.5"
    {...props}
  >
    {children}
  </Box.Absolute>
  )

export default Tag
