import React from 'react';
import PropTypes from 'prop-types';

import { firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

class LogoutPage extends React.PureComponent {
  componentDidMount() {
    this.props.firebase.logout();
  }

  render() {
    return (
      <Helmet>
        <title>登出 - 台灣小吃大調查</title>
      </Helmet>
    )
  }
}

LogoutPage.propTypes = {
  firebase: PropTypes.shape({
    logout: PropTypes.func,
  }),
};

export default firebaseConnect()(LogoutPage);
