import { useState } from "react"
import { Input } from "@chakra-ui/react";
import styled from '@emotion/styled'
import { responsive } from "./ThemeProvider/theme";

import useAnalytics from "../services/firebase/useAnalytics";

const StyledInput = styled(Input)`
`

const ReInput = ({ label, searchValue, logEvent, ...props }) => {
  const [isBlur, setBlur] = useState(true)
  const analytics = useAnalytics()
  return (
    <StyledInput
      border="2px solid"
      borderColor="black"
      borderRadius="2.5rem"
      fontSize={responsive('1em', '1.125em')}
      py="1.25rem"
      px="2rem"
      height="auto"
      fontWeight="bold"
      boxShadow={(!props.value || !isBlur) && '2px 2px 4px 1px rgba(0, 0, 0, 0.25)'}
      _hover={{
        borderColor: 'black',
      }}
      _focus={{
        outline: 'none'
      }}
      _placeholder={{
        color: 'customGray',
        fontWeight: 400
      }}
      onBlur={() => setBlur(true)}
      onClick={() => {
        if (logEvent) {
          analytics.logEvent('click', {
            name: logEvent
          });
        }
        setBlur(false)
      }}
      {...props}
      bg={(props.value && isBlur) && 'darkGray'}
    />
  )
}

export default ReInput
