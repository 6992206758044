import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import Button from '../components/Button';
import Flex from '../components/Flex';
import Text from '../components/Text';
import { responsive } from './ThemeProvider/theme';

const ComfirmModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="90%" borderRadius="2em" border="2px solid">
        <ModalCloseButton _focus={{ outline: 'none' }} size="lg" />
        <ModalBody pt={responsive('5.625rem', '3.5em')} pb={responsive('1.875rem', '1.25em')}>
          <Text fontWeight="700" fontSize="1.25em">
            你確定要離開嗎?<br />
            已經作答的資料會不見喔！
          </Text>
          <Flex flexDir={responsive('column-reverse', 'row')} justify="space-between" pt="3.125em">
            <Button width={responsive('100%', '48.5%')} to="/" onClick={onClose}>離開</Button>
            <Button.Black mb={responsive('0.75em', 0)} width={responsive('100%', '48.5%')} py="0.75rem" onClick={onClose}>繼續作答</Button.Black>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ComfirmModal
