import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import headerContext from '../../contexts/header/context';
import Box from '../../components/Box'
import HelloIslander from './HelloIslander';
import { useLocalStorage } from 'react-use';

import Leader from './Leader';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Leaderboard = () => {
  useFirebaseConnect('cityScores')
  const cityScores = useSelector(state => state.firebase.data.cityScores)
  useFirebaseConnect('localRepreScores')
  const localRepreScores = useSelector(state => state.firebase.data.localRepreScores)
  useFirebaseConnect('localStores')
  const localStores = useSelector(state => state.firebase.data.localStores)
  useFirebaseConnect('localStoresFood')
  const localStoresFood = useSelector(state => state.firebase.data.localStoresFood)
  useFirebaseConnect('localStoresNote')
  const localStoresNote = useSelector(state => state.firebase.data.localStoresNote)

  const { setHideHeader } = useContext(headerContext)
  // const topCity = sortBy(map(cityScores, (value, key) => ({ name: key, value })), 'value').reverse()[0]
  const [city, setCity] = useLocalStorage('city', '全台灣');
  // const [city, setCity] = useState(defalutValue)
  useEffect(() => {
    setHideHeader(false)
    return () => setCity('全台灣')
  }, [])
  if (!localRepreScores || !localStoresFood || !localStores || !cityScores || !localStoresNote) return null
  return (
    <Box>
      <Helmet>
        <title>即時排名 - 台灣小吃大調查</title>
      </Helmet>
      <Leader
        localRepreScores={localRepreScores}
        localStoresFood={localStoresFood}
        localStores={localStores}
        cityScores={cityScores}
        localStoresNote={localStoresNote}
        city={city}
        setCity={setCity}
      />
      <HelloIslander />
      <Footer />
    </Box>
  )
}

export default Leaderboard
