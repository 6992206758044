import React, { useState } from 'react'

import ReactSelect from './ReactSelect'

const ReactSelectInput = (props) => {
  const [inputValue, setInputValue] = useState('')
  return (
    <ReactSelect
      isCreatable
      isClearable
      inputValue={inputValue}
      onInputChange={setInputValue}
      menuIsOpen={Boolean(inputValue)}
      formatCreateLabel={newValue => `新增 "${newValue}"`}
      createOptionPosition="first"
      {...props}
    />
  )
}

export default ReactSelectInput
