import React from 'react';
import { Button } from "@chakra-ui/react";

import Link from './Link'
import { responsive } from './ThemeProvider/theme';

import useAnalytics from '../services/firebase/useAnalytics';

const ReButton = ({ href, to, logEvent, onClick, ...props }) => {
  const analytics = useAnalytics()
  if (!props.isDisabled && (href || to)) return <Button as={p => <Link {...p} onClick={onClick} logEvent={logEvent} href={href} to={to} />} {...props} />
  return <Button onClick={(e) => {
    if (typeof onClick === 'function' ) {
      onClick(e)
    }
    if (logEvent) {
      analytics.logEvent('click', {
        name: logEvent
      });
    }
  }}
    {...props}
  />
}

ReButton.defaultProps = {
  color: 'black',
  bg: 'white',
  border: '2px solid',
  borderColor: 'black',
  borderRadius: '2.5rem',
  fontSize: '1.25em',
  lineHeight: '1.5',
  boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.25)',
  py: responsive('0.5rem', '0.625rem'),
  fontWeight: '900',
  height: 'auto',
  _hover: {
    bg: 'darkGray',
    color: 'black',
    textDecoration: 'none',
    outline: 'none'
  },
  _focus: {
    outline: 'none',
    boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.25)',
  },
}

ReButton.Secondary = props => (
  <ReButton colorScheme="green" {...props} />
)

ReButton.Black = props => (
  <ReButton
    bg="black"
    color="white"
    _disabled={{
      cursor: 'not-allowed',
      bg: 'darkGray',
      borderColor: "darkGray",
    }}
    {...props}
  />
)

ReButton.Transparent = props => (
  <ReButton
    bg="transparent"
    boxShadow="none"
    border="transparent"
    _hover={{ bg: 'transparent' }}
    _focus={{
      outline: 'none',
      boxShadow: 'none',
      bg: 'white'
    }}
    {...props}
  />
)

ReButton.Gray = props => (
  <ReButton
  color="white"
  bg="darkGray"
  border="2px solid"
  borderColor="darkGray"
  _hover={{ bg: props.isDisabled ? 'darkGray' : 'black', textDecoration: 'none', }} {...props} />
)

ReButton.Danger = props => (
  <ReButton colorScheme="red" {...props} />
)

export default ReButton
